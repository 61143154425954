import { useModalContext } from "../WebinarAdminCreateWebinarTab/ModalContext";
import axios from "../../api/axios";
import { useContext, useEffect, useState } from "react";
import { RootStore } from "../../store/store";
import Swal from "sweetalert2";

const DeleteUserModal = ({ userId, setDeleteUserModal, username }) => {
  const [allChatUser, SetAllChatUser] = useState([]);
  const { handleDeleteUser } = useModalContext();
  const [store, setStore] = useContext(RootStore);
  const { userStore } = store;

  const handleCloseCancelModal = () => {
    setDeleteUserModal(false);
  };

//   console.log("delete modal:", userId);
//   console.log("delete modal:", userId , selectedChatUserObject?.id);

  return (
    <div className="ir-ws-position-fixed ir-ws-sign-popup-container ">
      <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
        <div className="ir-ws-signup-content-container">
          <div
            className="ir-ws-position-absolute ir-ws-signup-close-container"
            onClick={handleCloseCancelModal}
          >
            <span>X</span>
          </div>
          <div className="ir-ws-signup-content-inner-container">
            <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
              Are you sure you want to delete this user?
            </h3>
          </div>
          <div className="ir-ws-deleteModal-button-container">
            <button
              className="ir-ws-deleteModal-button-yes"
              onClick={async () => {
                try {
                  if (userId) {
                    await handleDeleteUser(userId);
                    // await deleteUser(userId);
                    handleCloseCancelModal();
                  }
                } catch (error) {
                  console.error("Delete Webinar failed:", error);
                }
              }}
            >
              Yes
            </button>
            <button
              className="ir-ws-deleteModal-button-no"
              onClick={handleCloseCancelModal}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteUserModal;
