import React, { useContext, useState, useRef, useEffect } from "react";
import "./ChatProfile.scss";
import { RootStore } from "../../../store/store";
import { useModalContext } from "../../WebinarAdminCreateWebinarTab/ModalContext";
import ImageEditorModal from "./ImageEditorModal";
import axios from "../../../api/axios";
import AllVoters from "./AllVoters";
import Swal from "sweetalert2";
// import AllVoters from "./AllVoters";

const ChatProfile = ({
  activeChatRoom,
  updateChatRoom,
  getChatHistory,
  chatData,
}) => {
  const [store, setStore] = useContext(RootStore);
  const { chatStore } = store;
  const { groups } = chatStore;
  const [storeChatHistory, setStoreChatHistory] = useState([]);
  const [groupData, setGroupData] = useState({});
  const [openImageGalleryModal, setOpenImageGalleryModal] = useState(false);
  const [activeCaseName, setActiveCaseName] = useState("");
  const [nextButtonDisable, setNextButtonDisable] = useState(false);
  const [prevButtonDisable, setPreButtonDisable] = useState(false);
  const [groupUsers, setGroupUsers] = useState([]);
  const [groupUsersLoading, setGroupUsersLoading] = useState(true);

  const [searchQuery, setSearchQuery] = useState("");
  const {
    isSearchOverlayOpen,
    toggleSearchOverlay,
    messageRefs,
    searchTerm,
    setSearchTerm,
    setHighlightedMessageId,
    setChatImageEditURL,
    setIsImageEditorOpen,
    toggleGroupInfoOverlay,
    isGroupInfoOverlayOpen,
    setIsGroupInfoOverlayOpen,
    isAllMediaOverlayOpen,
    setIsAllMediaOverlayOpen,
    toggleAllMediaOverlay,
    closeAllMediaOverlay,
    showAllVoters,
    setShowAllVoters,
    currentPollId,
    closeAllVoters,
    setCurrentPollId,
    isSynopsisVisible,
    setIsSynopsisVisible,
    activeChatRoomGlobal,
    setActiveChatRoomGlobal,
    synopsisData,
    setSynopsisData,
    isSearchActive,
    setIsSearchActive,
    isViewMoreMenuOpen,
    setIsViewMoreMenuOpen,
  } = useModalContext();
  //   const [searchTerm, setSearchTerm] = useState("");
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);
  // const [mediaMessages, setMediaMessages] = useState([]);

  const [imageMessages, setImageMessages] = useState([]);
  const [videoMessages, setVideoMessages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageMediaOpen, setIsImageMediaOpen] = useState(false);
  // const [selectedImage, setSelectedImage] = useState("");
  const [documentMessages, setDocumentMessages] = useState([]);

  const [activeTab, setActiveTab] = useState("media");

  const [isAllMediaImageGalleryOpen, setIsAllMediaImageGalleryOpen] =
    useState(false);
  const [activeMediaIndex, setActiveMediaIndex] = useState(null);
  const [imageActiveIndex, setImageActiveIndex] = useState(0);

  //   const [isEditorOpen, setIsEditorOpen] = useState(false);

  const ref = useRef();

//   console.log("showAllVoters", showAllVoters);



  useEffect(() => {
    setStoreChatHistory(chatStore.chatHistory[activeChatRoom]);
  }, [activeChatRoom, chatStore]);

  // console.log("storeChatHistory", storeChatHistory);

  const handleMessageClick = (msgId) => {
    const messageRef = messageRefs[msgId];
    // console.log("Clicked");
    setHighlightedMessageId(msgId);
    if (messageRef && messageRef.current) {
      messageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }

    setTimeout(() => setHighlightedMessageId(null), 3000);
  };

  const [carouselActiveIndex, setCarouselActiveIndex] = useState(0);
  const onCarouselMenuItemclick = (carouselActiveIndex) => {
    setCarouselActiveIndex(carouselActiveIndex);
  };

  const showLargeImage = () => {
    setOpenImageGalleryModal(true);
    console.log("chatprofile");
  };

  const openImageModal = (index) => {
    setImageActiveIndex(index);
    setIsImageMediaOpen(true);
  };
  const closeImageGalleryModal = () => {
    setOpenImageGalleryModal(false);
  };

  const prevImageMediaIndex = () => {
    setImageActiveIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : imageMessages.length - 1
    );
  };

  const nextImageMediaIndex = () => {
    setImageActiveIndex((prevIndex) =>
      prevIndex < imageMessages.length - 1 ? prevIndex + 1 : 0
    );
  };

  const leftCarouselIndex = () => {
    if (carouselActiveIndex > 0) {
      setCarouselActiveIndex(carouselActiveIndex - 1);
    }
  };
  const rightCarouselIndex = () => {
    // const allImages = chatHistory[props.activeChatRoom].filter(i => i.images !== "").map(e => e.images)
    const allImages = groupData?.image?.map((e) => e.image);
    console.log("all images erwe", allImages);
    if (carouselActiveIndex < allImages.length - 1) {
      setCarouselActiveIndex(carouselActiveIndex + 1);
      // setNextButtonDisable(true);
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, []);

  useEffect(() => {
    if (groups.length > 0) {
      let group = groups.find((e) => e.id === parseInt(activeChatRoom));
      setGroupData(group);
      console.log(" ", group);
      setActiveCaseName(group?.name);
    }
  }, [activeChatRoom, chatStore.groups]);

  useEffect(() => {
    let isPreviousDiabled = carouselActiveIndex <= 0 ? true : false;
    const allImages = groupData?.image?.map((e) => e.image) || [];
    let isNextDiabled =
      carouselActiveIndex >= allImages.length - 1 ? true : false;

    setPreButtonDisable(isPreviousDiabled);
    setNextButtonDisable(isNextDiabled);
  }, [carouselActiveIndex]);

  useEffect(() => {
    // Check if carouselActiveIndex is at the beginning or end to disable/enable buttons.
    setPreButtonDisable(carouselActiveIndex <= 0);
    setNextButtonDisable(
      carouselActiveIndex >= (groupData?.image?.length || 0) - 1
    );
  }, [carouselActiveIndex, groupData]);

  useEffect(() => {
    if (groupData?.id && chatData) {
      // console.log("chat data in profile:",groupData, chatData[activeChatRoom]);
      // Extract the messages for the active chat room from chatData
      const history = chatData[groupData.id] || [];
      console.log(
        "Chat history from chatData for room ID",
        groupData.id,
        ":",
        history
      );

      setChatHistory(history);
    }
  }, [groupData, chatData]);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
  };

  useEffect(() => {
    if (searchTerm) {
      const lowerSearchTerm = searchTerm.toLowerCase();
      const filtered = chatHistory.filter((msg) =>
        msg.message.toLowerCase().includes(lowerSearchTerm)
      );
      setFilteredMessages(filtered);
    } else {
      setFilteredMessages([]);
    }
  }, [searchTerm, chatHistory]);

  const highlightText = (text, searchTerm) => {
    if (!searchTerm) return text;

    let highlighted = false; // Flag to check if the term has been highlighted
    const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));

    return parts.map((part, index) => {
      if (!highlighted && part.toLowerCase() === searchTerm.toLowerCase()) {
        highlighted = true; // Mark that we’ve highlighted once
        return (
          <span key={index} className="highlight">
            {part}
          </span>
        );
      }
      return part;
    });
  };

  useEffect(() => {
    const fetchGroupUsers = async () => {
      setGroupUsersLoading(true);
      try {
        const response = await axios({
          url: `/group/${activeChatRoom}/users`,
          method: "get",
          withCredentials: false,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
          },
        });

        console.log("response", response.data);
        setGroupUsers(response.data);

        setGroupUsersLoading(false);

        if (groupUsers) {
          console.log("response1", groupUsers);
        }
      } catch (error) {
        console.error("Error fetching group users:", error);
      }
    };

    if (isGroupInfoOverlayOpen && activeChatRoom) {
      fetchGroupUsers();
    }
  }, [isGroupInfoOverlayOpen, activeChatRoom]);

  const openAllMediaImageGallery = (index) => {
    // console.log("Opening gallery for index:", index);
    setActiveMediaIndex(index);
    setIsAllMediaImageGalleryOpen(true);
    // console.log("Gallery Open State:", isAllMediaImageGalleryOpen);
    // console.log("Active Media Index:", activeMediaIndex);
  };

  const closeAllMediaImageGallery = () => {
    // Pause all videos
    const videoElements = document.querySelectorAll(".ir-car-img video");
    videoElements.forEach((video) => video.pause());

    setIsAllMediaImageGalleryOpen(false);
    setActiveMediaIndex(null);
    setIsImageMediaOpen(false);
    // console.log("Gallery Open State:", isAllMediaImageGalleryOpen);
  };

  const prevMediaIndex = () => {
    // Pause current video
    const currentVideo = document.querySelector(`.iks-carousel-active video`);
    if (currentVideo) currentVideo.pause();

    setActiveMediaIndex((prev) =>
      prev === 0 ? videoMessages.length - 1 : prev - 1
    );
  };

  const nextMediaIndex = () => {
    // Pause current video
    const currentVideo = document.querySelector(`.iks-carousel-active video`);
    if (currentVideo) currentVideo.pause();

    setActiveMediaIndex((prev) =>
      prev === videoMessages.length - 1 ? 0 : prev + 1
    );
  };


  useEffect(() => {
    if (storeChatHistory) {
      // if (chatData && chatData[activeChatRoom]) {
      const activeRoomChats = storeChatHistory;
      //   const activeRoomChats = chatData[activeChatRoom];

      // Separate media into images and videos
      const images = activeRoomChats
        .filter((chat) => chat.images && chat.images.trim() !== "")
        .map((chat) => chat.images.trim()) // Extracting image links
        .reverse();

      const videos = activeRoomChats
        .filter((chat) => chat.videos && chat.videos.trim() !== "")
        .map((chat) => chat.videos.trim()) // Extracting video links
        .reverse();

      // Filter for messages with documents
      const documents = activeRoomChats
        .filter((chat) => chat.documents && chat.documents.trim() !== "")
        .reverse();

      // Set states
      setImageMessages(images);
      if (images.length > 0) {
        setSelectedImage(images[0]);
      }
      setVideoMessages(videos);
      setDocumentMessages(documents);
    }
  }, [chatData, activeChatRoom, storeChatHistory, chatStore]);

  const fetchSynopsis = async (roomId) => {
    setIsSynopsisVisible(true);
    try {
      const response = await axios({
        url: `get/synopsis/${roomId}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtTokenApi1")}`,
        },
      });

      if (response) {
        setSynopsisData(response.data.synopsis);
        setIsSynopsisVisible(true);
      } else {
        Swal.fire({
          title: "No Synopsis Found",
          text: "This room does not have a synopsis available.",
          icon: "info",
        });
      }
    } catch (error) {
      console.error("Error fetching synopsis:", error);
      Swal.fire({
        title: "Error",
        text: "Failed to fetch synopsis data.",
        icon: "error",
      });
    }
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     console.log("Image Messages:", imageMessages);
  //     console.log("Video Messages:", videoMessages);
  //   }, 10000); // Logs every 10 seconds

  //   // Cleanup function to clear the interval when the component unmounts
  //   return () => clearInterval(interval);
  // }, [imageMessages, videoMessages]);
  return (
    <div
      className="ir-chat-profile-main-container tab-content-scroll ps ps--active-y"
      ref={ref}
    >
      <div className="ir-chpr-top-bar-main-cont">
        <div className="ir-chpr-top-bar-cont">
          <div
            className={`ir-chat-top-head-invite-media `}
            onClick={(e) => e.stopPropagation()}
            title="Media"
          >
            <img
              src={require("../../../assets/images/media-img.png")}
              alt="media"
              onClick={() => {
                setIsViewMoreMenuOpen(false);
                toggleAllMediaOverlay();
                setIsSearchActive(false);
              }}
            />
          </div>
          <div
            className="ir-chat-top-head-invite"
            onClick={(e) => e.stopPropagation()}
            title="Synopsis"
          >
            <i
              className="bi bi-eye"
              onClick={() => {
                fetchSynopsis(activeChatRoomGlobal?.id);
                toggleAllMediaOverlay();
                setIsAllMediaOverlayOpen(false)
              }}
            ></i>
          </div>
        </div>
        {/* <div
          className="ir-chat-top-head-invite"
          // onClick={(e) => e.stopPropagation()}
          // title="Synopsis"
        >
          <i class="bi bi-x-lg"></i>
        </div> */}
      
        {showAllVoters ? (
          <div
            className="ir-chat-top-head-invite"
            onClick={() => {
              setCurrentPollId(null);
              setShowAllVoters(false);
            }}
            // title="Synopsis"
          >
            <i class="bi bi-x-lg"></i>
          </div>
        ) : isGroupInfoOverlayOpen ? (
          <div
            className="ir-chat-top-head-invite"
            onClick={() => setIsGroupInfoOverlayOpen(false)}
          >
            <i class="bi bi-x-lg"></i>
          </div>
        ) : isAllMediaOverlayOpen ? (
          <div
            className="ir-chat-top-head-invite"
            // onClick={(e) => e.stopPropagation()}
            // title="Synopsis"
            onClick={(e) => {
              e.stopPropagation();
              setIsAllMediaOverlayOpen(false);
              setIsImageMediaOpen(false);
            }}
          >
            <i class="bi bi-x-lg"></i>
          </div>
        ) :   isSynopsisVisible && synopsisData ?  <div
            className="ir-chat-top-head-invite"
            onClick={() => setIsSynopsisVisible(false)}
          >
            <i class="bi bi-x-lg"></i>
          </div>: (
          <div
            className="ir-chat-top-head-invite"
            // onClick={(e) => e.stopPropagation()}
            // title="Synopsis"
            onClick={() => updateChatRoom(true)}
          >
            <i class="bi bi-pencil"></i>
          </div>
        )}
      </div>

      {isAllMediaImageGalleryOpen && (
        <div className="iks-mint-cust-modal ir-chat-inside-modal-container ir-image-preview-main-modal">
          <div className="iks-mint-modal-container">
            <div className="ir-thumb-image-inner-container">
              <div className="ir-image-large-thumb-title-container">
                <h3>Media Gallery</h3>
                <div className="ir-image-head-thumb-right">
                  <button onClick={closeAllMediaImageGallery}>
                    <i className="bi bi-x-lg"></i>
                  </button>
                </div>
              </div>
              <div className="ir-modal-thumb-container-large-image">
                {videoMessages?.map((video, id) => (
                  <div
                    key={id}
                    className={`iks-cine-content iks-cine-content-${id} ${
                      id === activeMediaIndex
                        ? "iks-carousel-active"
                        : "iks-carousel-inactive"
                    }`}
                  >
                    <video
                      className="ir-car-img"
                      src={video}
                      controls
                      loop={false}
                    />
                  </div>
                ))}

                <button
                  onClick={prevMediaIndex}
                  disabled={videoMessages.length === 1}
                  className={`ir-left-car-btn ir-carousel-action-btns`}
                >
                  <i className="bi bi-chevron-left"></i>
                </button>
                <button
                  onClick={nextMediaIndex}
                  disabled={videoMessages.length === 1}
                  className={`ir-right-car-btn ir-carousel-action-btns`}
                >
                  <i className="bi bi-chevron-right"></i>
                </button>
              </div>
              <div className="ir-thumbanil-modal-image">
                {videoMessages?.map((video, id) => (
                  <div
                    key={id}
                    onClick={() => setActiveMediaIndex(id)}
                    className={`ir-car-item-container ${
                      id === activeMediaIndex
                        ? "iks-carousel-item-active"
                        : "iks-carousel-item-inactive"
                    }`}
                  >
                    <video className="ir-car-img" src={video} muted />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {isImageMediaOpen && (
        <div className="iks-mint-cust-modal ir-chat-inside-modal-container ir-image-preview-main-modal">
          <div className="iks-mint-modal-container">
            <div className="ir-thumb-image-inner-container">
              <div className="ir-image-large-thumb-title-container">
                <h3>Media Gallery</h3>
                <div className="ir-image-head-thumb-right">
                  <button onClick={closeAllMediaImageGallery}>
                    <i className="bi bi-x-lg"></i>
                  </button>
                </div>
              </div>
              <div className="ir-modal-thumb-container-large-image">
                {imageMessages?.map((image, id) => (
                  <div
                    key={id}
                    className={`iks-cine-content iks-cine-content-${id} ${
                      id === imageActiveIndex
                        ? "iks-carousel-active"
                        : "iks-carousel-inactive"
                    }`}
                  >
                    <img className="ir-car-img" src={image} alt="img" />
                  </div>
                ))}

                <button
                  onClick={prevImageMediaIndex}
                  disabled={imageMessages.length === 1}
                  className={`ir-left-car-btn ir-carousel-action-btns`}
                >
                  <i className="bi bi-chevron-left"></i>
                </button>
                <button
                  onClick={nextImageMediaIndex}
                  disabled={imageMessages.length === 1}
                  className={`ir-right-car-btn ir-carousel-action-btns`}
                >
                  <i className="bi bi-chevron-right"></i>
                </button>
              </div>
              <div className="ir-thumbanil-modal-image">
                {imageMessages?.map((image, id) => (
                  <div
                    key={id}
                    onClick={() => setImageActiveIndex(id)}
                    className={`ir-car-item-container ${
                      id === imageActiveIndex
                        ? "iks-carousel-item-active"
                        : "iks-carousel-item-inactive"
                    }`}
                  >
                    <img className="ir-car-img" src={image} alt="img" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {showAllVoters ? (
        <AllVoters
          chatHistory={storeChatHistory}
          selectedPollId={currentPollId}
          // onClose={() => {
          //   setCurrentPollId(null);
          //   setShowAllVoters(false);
          // }}
        />
      ) : isGroupInfoOverlayOpen ? (
        <div className="group-info-overlay">
          {/* <button
            type="button"
            class="close"
            aria-label="Close"
            onClick={() => setIsGroupInfoOverlayOpen(false)}
          >
            <span aria-hidden="true" className="close-button ">
              &times;
            </span>
          </button> */}
          <h4 className="groupInfo-title">Case info</h4>
          <div className="group-header">
            <img
              src={
                groupData?.image[0] ||
                require("../../../assets/images/chat-images/no-image.png")
              }
              alt={groupData?.name}
              className="group-header-image"
            />
            <h1 className="group-header-name">{groupData?.name}</h1>
            <p className="group-header-des">{groupData?.description}</p>
          </div>

          <span>
            {groupUsersLoading ? (
              <div className="shimmer shimmer-text"></div>
            ) : (
              `${groupUsers?.userCount || 0} members`
            )}
          </span>
          <div className="group-users-list">
            {groupUsersLoading ? (
              Array.from({ length: 5 }).map((_, i) => (
                <div key={i} className="shimmer-user-item">
                  <div className="shimmer shimmer-avatar"></div>
                  <div className="shimmer shimmer-name"></div>
                </div>
              ))
            ) : groupUsers?.users?.length > 0 ? (
              groupUsers.users.map((user, i) => (
                <div key={i} className="user-item">
                  <img
                    src={
                      user?.userImage ||
                      `https://ui-avatars.com/api/?name=${encodeURIComponent(
                        user?.userName || "User"
                      )}&background=47bc94&color=FFFFFF&font-weight=800`
                    }
                    alt={user?.userName}
                    className="user-avatar"
                  />
                  <span className="user-name">
                    {user?.userName ? user?.userName : "Deleted user"}
                  </span>
                </div>
              ))
            ) : (
              <p className="no-users">No users found in this group.</p>
            )}
          </div>
        </div>
      ) : isAllMediaOverlayOpen ? (
        <div className="ir-md-overlay">
          <div className="ir-md-overlay-content">
            <div className="ir-md-tabs ir-ws-chat-media-btn">
              <button
                className={`ir-md-tab-btn ${
                  activeTab === "media" ? "ir-md-active-tab" : ""
                }`}
                onClick={() => setActiveTab("media")}
              >
                Media
              </button>
              <button
                className={`ir-md-tab-btn ${
                  activeTab === "documents" ? "ir-md-active-tab" : ""
                }`}
                onClick={() => setActiveTab("documents")}
              >
                Documents
              </button>
              {/* <button
                className="ir-md-close-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsAllMediaOverlayOpen(false);
                }}
              >
                &times;
              </button> */}
            </div>
            <div className="ir-md-tab-content">
              {activeTab === "media" && (
                <div className="ir-md-media-content">
                  <div className="ir-md-media-content-main-img-box">
                    <p className="ir-md-media-case-text">Case Images</p>
                    <div className="ir-md-media-image-cont">
                      <div className="ir-md-media-image-box">
                        {imageMessages.length > 0 && (
                          <img
                            src={selectedImage}
                            alt="media"
                            className="ir-md-media-main-image"
                            onClick={() => {
                              // setIsImageMediaOpen(true);
                               openImageModal(imageMessages.indexOf(selectedImage))
                              // openAllMediaImageGallery(imageActiveIndex);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {imageMessages.length > 0 ? (
                    <div className="ir-md-media-thumbnails-container">
                      {imageMessages.map((image, index) => (
                        <div
                          key={index}
                          className={`ir-md-thumbnail ${
                            selectedImage === image
                              ? "ir-md-thumbnail-active"
                              : ""
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedImage(image);
                            // setImageActiveIndex(index);
                            // openAllMediaImageGallery(index);
                            // openAllMediaImageGallery(imageActiveIndex);
                          }}
                        >
                          <img
                            src={image}
                            alt={`Media ${index + 1}`}
                            className="ir-md-media-thumbnail"
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p style={{ marginTop: "16px" }}>No images available</p>
                  )}

                  <div className="ir-md-media-video-cont">
                    <div className="ir-md-video-dash-cont">
                      <div className="ir-md-video-dash"></div>
                      <div className="ir-md-video-dash"></div>
                    </div>
                    <p className="ir-md-media-video-text">Case Videos</p>
                    {videoMessages?.length > 0 ? (
                      <div className="ir-md-media-video-thumbnails-container">
                        {videoMessages.map((video, index) => (
                          <div
                            key={index}
                            className="ir-md-video-thumbnail-box"
                            onClick={(e) => {
                              e.stopPropagation();
                              openAllMediaImageGallery(index);
                            }}
                          >
                            <div className="ir-md-video-thumbnail-parent-box">
                              <video
                                src={video}
                                className="ir-md-media-thumbnail-box"
                              />
                              <div className="ir-md-play-icon">
                                <i class="bi bi-play-fill"></i>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p style={{ marginTop: "16px" }}>No videos available</p>
                    )}
                  </div>
                </div>
              )}
              {activeTab === "documents" && (
                <div className="ir-md-document-content">
                  {documentMessages?.length > 0 ? (
                    documentMessages?.map((doc, index) => (
                      <div key={index} className="ir-md-document-item">
                        <a href={doc.documents} download>
                          {doc.documents
                            ? doc.documents.split("/").pop()
                            : "Unknown Document"}
                          <i className="bi bi-download"></i>
                        </a>
                      </div>
                    ))
                  ) : (
                    <p>No documents available</p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      ) :          isSynopsisVisible && synopsisData ?(
            <div
              className={`ir-synopsis-sidebar ${
                isSynopsisVisible ? "show" : ""
              }`}
            >
              <div className="ir-synopsis-header">
                <h5 className="ir-synopsis-title">
                 {activeChatRoomGlobal.name}
                </h5>
                {/* <button
                  type="button"
                  className="ir-synopsis-close-btn"
                  onClick={() => setIsSynopsisVisible(false)}
                >
                  X
                </button> */}
              </div>
              <div className="ir-synopsis-body">
                <h6>Details:</h6>
                <p>{synopsisData.details || "No details available"}</p>
                <h6>Objective:</h6>
                <p>{synopsisData.objective || "No objective available"}</p>
              </div>
            </div>
          ): (
        <>
          {/* <div className="ir-car-update-btn-container" title="Update Case">
            <button
              className="btn btn-primary btn-lg btn-block btn-round-update"
              id="newChat"
              onClick={() => updateChatRoom(true)}
            >
              <i className="bi bi-pencil"></i>
            </button>
          </div> */}
          <p className="ir-chpr-case-text">Case Images</p>
          <div className="ir-chat-profile-inner-container">
            {groupData?.image?.map((image, id) => (
              <div
                key={id}
                className={`iks-cine-content iks-cine-content-${id} ${
                  id !== carouselActiveIndex ? "" : "iks-chpr-media-cont"
                }  ${
                  id === carouselActiveIndex
                    ? "iks-carousel-active"
                    : "iks-carousel-inactive iks-chpr-media-cont"
                }`}
              >
                <img
                  className="ir-car-img"
                  src={image}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = require("../../../assets/images/chat-images/no-image.png");
                  }}
                  alt={"chat group"}
                  onClick={() => showLargeImage(image)}
                />
              </div>
            ))}

            <div className="ir-car-item-main-container ir-chpr-image-main-cont">
              {groupData?.image?.map((image, id) => (
                <div
                  key={id}
                  className="ir-car-item-container ir-chpr-image-box"
                >
                  <img
                    onClick={() => onCarouselMenuItemclick(id)}
                    className={`ir-car-item-img ${
                      carouselActiveIndex === id ? "active-image-border" : ""
                    }`}
                    src={image}
                    alt={"chat group"}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = require("../../../assets/images/chat-images/no-image.png");
                    }}
                  />
                </div>
              ))}
            </div>
          </div>

          {openImageGalleryModal && (
            <div className="iks-mint-cust-modal ir-chat-inside-modal-container ir-image-preview-main-modal">
              <div className="iks-mint-modal-container">
                <div className="ir-thumb-image-inner-container">
                  <div className="ir-image-large-thumb-title-container">
                    <h3>{activeCaseName}</h3>
                    <div className="ir-image-head-thumb-right">
                      <button onClick={closeImageGalleryModal}>
                        <i className="bi bi-x-lg"></i>
                      </button>
                    </div>
                  </div>
                  <div className="ir-modal-thumb-container-large-image">
                    {groupData?.image?.map((image, id) => (
                      <div
                        key={id}
                        className={`iks-cine-content iks-cine-content-${id} ${
                          id === carouselActiveIndex
                            ? "iks-carousel-active"
                            : "iks-carousel-inactive"
                        }`}
                      >
                        <img
                          className="ir-car-img"
                          src={image}
                          alt={"chat group"}
                        />
                      </div>
                    ))}

                    <button
                      onClick={leftCarouselIndex}
                      disabled={prevButtonDisable}
                      className={`ir-left-car-btn ir-carousel-action-btns`}
                    >
                      <i className="bi bi-chevron-left"></i>
                    </button>
                    <button
                      onClick={rightCarouselIndex}
                      disabled={nextButtonDisable}
                      className={`ir-right-car-btn ir-carousel-action-btns`}
                    >
                      <i className="bi bi-chevron-right"></i>
                    </button>
                  </div>
                  <div className="ir-thumbanil-modal-image">
                    {groupData?.image?.map((image, id) => (
                      <div
                        key={id}
                        onClick={() => onCarouselMenuItemclick(id)}
                        className={`ir-car-item-container ${
                          id === carouselActiveIndex
                            ? "iks-carousel-item-active"
                            : "iks-carousel-item-inactive"
                        }`}
                      >
                        <img
                          className="ir-car-img"
                          src={image}
                          alt={"chat group"}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ChatProfile;
